import '../../splide/splide.min.css';
import Splide from "../../splide/splide.min.js";

let splide;
const splideSliders = {
  init: function () {
    splideSliders.slider();
    //actuHome.fuseWithAgenda();
  },
  slider: () => {
    // Les options par défaut pour ceux qui ne sont pas remplie dans l'attribut
    const options = {
      perPage: 3,
      perMove: 1,
      type: "slide",
      pagination: false,
      gap: "24px",
      autoplay: "pause",
      interval: 3000,
      pauseOnHover: false,
      i18n: {
        prev: "Elément précédent",
        next: "Elément suivant",
        first: "Premier élément",
        last: "Dernier élément",
        slideX: "Aller à l'élément %s",
        pageX: "Aller à la page %s",
        play: "Démarrer la lecture automatique",
        pause: "Pauser la lecture automatique",
        select: "Sélectionner un élément à afficher",
        slide: "Elément",
        slideLabel: "%s sur %s",
      },
      breakpoints: {
        1024: {
          perPage: 2,
          perMove: 1,
          pagination: false,
        },
        767: {
          perPage: 1,
          perMove: 1,
          pagination: false,
        },
      },
    };

    //homepage
    let splideHome = document.querySelectorAll( '.s-home .splide' );

    // Init all the elements with "splide" ClassName
    for ( let i = 0; i < splideHome.length; i++ ) {

      splide = new Splide(splideHome[ i ], options).mount();
    }

    let splideProfil = document.querySelectorAll( '.s-page-profile .splide' );

    // Init all the elements with "splide" ClassName
    for ( let i = 0; i < splideProfil.length; i++ ) {

      splide = new Splide(splideProfil[ i ], options).mount();
    }
  },
  fuseWithAgenda: () => {
    const actuHome = document.querySelector('.s-actu-home');
    const agendaHome = document.querySelector('.TPL_AGENDAHOME');

    // On place les agenda dans les actus
    actuHome.insertAdjacentHTML('beforeend', agendaHome.innerHTML);
    //On supprime les anciens agendas pour ne pas avoir de doublon
    agendaHome.remove();
    // On ajoute une classe qui va servir pour la nouvelle mise en page
    actuHome.classList.add('has-agenda');
    // On ajuste le nombre d'éléments affiché par le slider
    splide.options.perPage = 2;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  splideSliders.init();
});
